import { createContext } from 'react'

const MixContext = createContext({
	mixesIds: [
        '/certainegaiete/le-pain-est-politique-2%C3%A8me-partie/',
        '/certainegaiete/le-pain-est-politique-1%C3%A8re-partie/',
        '/certainegaiete/quartiers-vivants-regarder-la-ville-autrement/',
        '/certainegaiete/femmes-corps-et-confinement/',
        '/certainegaiete/les-oubli%C3%A9s-2%C3%A8me-partie/',
        '/certainegaiete/les-oubli%C3%A9s-1%C3%A8re-partie/',
        '/certainegaiete/ffp2-mon-amour-2-immunit%C3%A9-collective/',
        '/certainegaiete/interview-remy/',
        '/certainegaiete/ffp2-mon-amour-1-on-sfait-pas-la-bise/',
        '/certainegaiete/effondrement-%C3%A9co-f%C3%A9minisme-et-anti-colonialisme-partie-3-les-personnes-en-lutte-au-centre/',
        '/certainegaiete/effondrement-%C3%A9co-f%C3%A9minisme-et-anti-colonialisme/',
        '/certainegaiete/effondrement-%C3%A9co-f%C3%A9minisme-et-anti-colonialisme-partie-1/',
        '/certainegaiete/beauty-building-de-marine-ruby-et-sabina-sebastiani/',
        '/certainegaiete/la-morsure-del%C3%A9onore-dock-annick-grandry-et-pauline-jeanne/',
        '/certainegaiete/chante-toujours-de-nathalie-bourguignon/',
        '/certainegaiete/hommage-%C3%A0-antaki/'
	],
	mixes: [],
	widget: {},
	currentMix: '',
	playing: false,
	featuredMix: '',
    firstMix: 'https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=/certainegaiete/le-pain-est-politique-2%C3%A8me-partie/'
})

export default MixContext
