import React from 'react'

import 'react-process-string'

const processString = require('react-process-string');

class HelloWorld extends React.Component {
    render() {
        let config = [{
            regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
            fn: (key, result) => <span key={key}>
                                     <a target="_blank" href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}>{result[2]}.{result[3]}{result[4]}</a>{result[5]}
                                 </span>
        },
        // {
        //     regex: /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
        //     fn: (key, result) => <span key={key}>
        //                              <a target="_blank" href={`http://${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
        //                          </span>
        // }
      ];

        let stringWithLinks = this.props.text;
        let processed = processString(config)(stringWithLinks);

        return (
            <p style={{whiteSpace: "pre-wrap"}}>{processed}</p>
        );
    }
}

export default HelloWorld
