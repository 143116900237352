import React, { useContext } from 'react'
import Stat from './Stat'

import MixContext from '../context/mix-context'

const About = () => {
	const { mixes } = useContext(MixContext)

	return (
		<div className="ph3 ph4-l pad-bottom">
			<div className="measure center lh-copy">
				<p className="mt0">
					D1cg.fm présente sa collection de podcasts.
				</p>
				<p className="mb4">

				</p>
			</div>

			<div>
				<Stat
					statName="Présente..."
					statNumber={mixes.length}
					statWord="podcasts"
				/>
				<Stat
					statName="Joués..."
					statNumber={mixes.reduce(
						(accum, current) => accum + current.play_count,
						0
					)}
					statWord="fois"
				/>
				<Stat
					statName="Avec..."
					statNumber={mixes.reduce(
						(accum, current) => accum + current.audio_length,
						0
					)}
					statWord="secondes"
				/>
			</div>
		</div>
	)
}

export default About
